<template>
  <div>
    <b-row>
      <b-col>
        <Card class="py-3">
          <b-row>
            <b-col>
              <label>Nome:</label>
              <b-input v-model="model.name"></b-input>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <label>Descrição:</label>
              <b-input v-model="model.description"></b-input>
            </b-col>
          </b-row>
          <hr />
          <div class="d-flex justify-content-end">
            <b-button @click="save_data">Salvar</b-button>
          </div>
        </Card>
        <Card :title="'Carregar arquivo csv'" class="mt-3 pb-3"
          ><VueFileAgent
            class="mt-3"
            :multiple="false"
            :accept="'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
            v-model="uploadedFiles"
        /></Card>
      </b-col>
      <b-col>
        <Card>
          <div class="mt-3">
            <span class="text-muted mr-2"
              >Validar campos (campos encontrados no .csv)</span
            ><fa-icon
              icon="question-circle"
              class="text-muted"
              id="popover-target-1"
            ></fa-icon>
            <b-popover
              target="popover-target-1"
              triggers="hover"
              placement="top"
              variant="info"
            >
              <template #title>Validação de Campos</template>
              <div class="p-2">
                A validação de campos é para analizar o campo selecionado, e
                verificar se existe algum dado em branco. Lembrando que o
                sistema irá salvar o arquivo mesmo com campos em branco, é de
                responsabilidade do usuário, fazer as alterações necessárias.
              </div>
            </b-popover>
          </div>
          <hr />
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selectedVariables"
            :options="variables"
            name="flavour-1"
            stacked
          ></b-form-checkbox-group>
          <hr />
          <div class="d-flex justify-content-end mb-3">
            <b-button @click="check_errors">Validar</b-button>
          </div>
        </Card>
      </b-col>
    </b-row>
    <Card :title="'Dados lidos'" class="mt-3">
      <b-row class="my-3">
        <b-col class="d-flex align-items-baseline" md="12">
          <label class="mr-3">Filtrar</label>
          <b-input v-model="search"></b-input>
          <!-- <b-button style="flex-shrink: inherit" class="ml-2">Adicionar item manualmente</b-button> -->
        </b-col>        
      </b-row>
      <table class="table table-hover table-striped borderless scrollable">
        <thead>
          <th class="text-muted" v-for="item in variables" :key="item.value">
            <small>{{ item.text }}</small>
          </th>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="`items-${index}`">
            <td
              v-for="(normItem, idx) in normalize_item(item)"
              :key="`norm-${idx}`"
            >
              {{ normItem }}
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
    <SweetModal ref="checkErrors" overlay-theme="dark" width="95%">
      <table v-if="errors" class="table table-hoverborderless scrollable">
        <thead>
          <th class="text-muted" v-for="item in variables" :key="item.value">
            <small>{{ item.text }}</small>
          </th>
        </thead>
        <tbody>
          <tr v-for="(error, index) in errors" :key="`items-error-${index}`">
            <td v-for="(item, idx) in headers" :key="`norm-error-${idx}`">
              <b-input
                v-model="error.items[item]"
                :style="[
                  error.items[item] == '' ? { borderColor: 'red' } : false,
                ]"
              ></b-input>
            </td>
          </tr>
        </tbody>
      </table>
      <b-button @click="finish_changes">Finalizar</b-button>
    </SweetModal>
    <SweetModal ref="errorSize" overlay-theme="dark" width="95%">
      <template #title>
        <span class="lead">Atenção! Dados inválidos.</span>
      </template>
      <b-alert show
        ><span
          >O sistema entende o separador ponto e vírgula ( ; ), provavelmente o
          erro ocorreu devido a existência de ponto e vírgula em algum dos
          dados, para corrigi-lo remova os ponto e vírgula do dado
          correspondente (podendo ser alterado por vírgula por exemplo),
          deixando apenas os ponto e vírgula para separar as colunas. Abaixo
          existe uma tabela que reflete exatamente como ficarão os campos após a
          correção, lembrando que cada coluna da tabela mostra exatamente o dado
          alocado para aquele valor (cabeçalho da tabela)</span
        ></b-alert
      >
      <div
        class="d-flex"
        v-for="(item, index) in errorSize"
        :key="`errorsize${index}`"
      >
        <span class="lead mr-2">{{ index + 1 }}</span
        ><b-input v-model="item.item"></b-input>
      </div>

      <b-table class="mt-4" :items="normalizedErrorSize"> </b-table>
      <!-- <table v-if="errors" class="table table-hoverborderless scrollable">
        <thead>
          <th class="text-muted" v-for="item in variables" :key="item.value">
            <small>{{ item.text }}</small>
          </th>
        </thead>
        <tbody>
          <tr v-for="(error, index) in errors" :key="`items-error-${index}`">
            <td v-for="(item, idx) in headers" :key="`norm-error-${idx}`">              
              <b-input v-model="error.items[item]" :style="[error.items[item] == ''? {'borderColor':'red'}:false ]"></b-input>
            </td>
          </tr>
        </tbody>
      </table> -->
      <template #button>
        <div class="d-flex justify-content-end">
          <b-button @click="finish_changes">Finalizar</b-button>
        </div>
      </template>
    </SweetModal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      unformatedItems: [],
      errorSize: [],
      headers: [],
      errors: [],
      variables: [],
      rows: [],
      selectedVariables: [],
      html: "",
      uploadedFiles: [],
      selected: {
        application_id: "",
        active: "",
      },
      model: {
        id:'',
        unity_id: this.$store.state.auth.currentUser.unity_id,
        name: "",
        description: "",
        file: "",
        active: "1",
      },
    };
  },
  watch: {
    uploadedFiles: {
      handler(file) {
        const reader = new FileReader();
        reader.onload = (file) => {
          this.loadValues(file.target.result.split(/\r?\n/));
        };
        reader.readAsText(file[0].file);
      },
    },
  },
  computed: {
    normalizedErrorSize() {
      let item = [];
      _.forEach(this.errorSize, (error) => {
        console.log(error);
        const norm = error.item.split(";");
        let temp = {};
        _.forEach(norm, (item, index) => {
          temp[this.headers[index]] = item;
        });
        item.push(temp);
      });
      return item;
    },
    state() {
      return this.$store.state.campaigns;
    },
    applicationsById() {
      return _.keyBy(this.state.applications, "id");
    },
    selectOptionsUnityApplications() {
      const firstOption = { text: "Selecione", value: "", disabled: true };
      const normalized = _.map(this.state.unityApplications, (item) => {
        return {
          text: this.applicationsById[item.application_id].name,
          value: item.application_id,
        };
      });
      return [firstOption, ...normalized];
    },
    items() {
      let items = [];
      _.forEach(this.unformatedItems, (item) => {
        if (_.includes(item, this.search)) {
          items.push(item);
        }
      });
      return items;
    },
  },
  methods: {
    async save_data() {
      const normData = [];
      _.forEach(this.unformatedItems, (item, index) => {
        const temp = item.split(";");
        let tempItem = {};
        _.forEach(temp, (value, idx) => {
          tempItem[this.headers[idx]] = value;
        });
        normData.push(tempItem);
      });
      this.model.file = JSON.stringify(normData);
      this.model.id = this.get_uuid()
      await this.$store.dispatch("campaigns/save_campaign_files", this.model);
      console.log(this.model);
    },
    finish_changes() {
      _.forEach(this.errors, (error) => {
        this.unformatedItems.splice(
          error.index,
          1,
          Object.values(error.items).join(";")
        );
      });
    },
    normalize_item(item) {
      return item.split(";");
    },
    loadValues(data) {
      this.unformatedItems = [];
      this.errorSize = [];
      this.variables = [];
      this.headers = [];
      this.selectedVariables = [];
      this.headers = data[0].split(";");
      const size = this.headers.length;
      data.shift();
      _.forEach(this.headers, (item, index) => {
        this.selectedVariables.push(index);
      });
      _.forEach(data, (item, index) => {
        const norm = item.split(";");
        if (norm.length > size) {
          let normItem = item.replace(/['"]+/g, "");
          this.errorSize.push({ item: normItem, index });
        } else {
          if (item) {
            this.unformatedItems.push(item);
          }
        }
      });
      this.variables = _.map(this.headers, (header, index) => {
        return { text: header, value: index };
      });
      if (this.errorSize.length > 0) {
        this.$refs.errorSize.open();
      }
    },
    async get_models() {
      this.$store.dispatch("modelsCustom/get_models", this.selected);
    },
    check_errors() {
      this.errors = [];
      console.log(this.errors);
      _.forEach(this.unformatedItems, (item, index) => {
        const temp = item.split(";");
        if (temp.includes("")) {
          let tempErr = {
            index,
            items: {},
          };
          _.forEach(this.headers, (header, idx) => {
            tempErr.items[header] = temp[idx];
          });
          _.forEach(this.selectedVariables, (variable) => {
            if (tempErr.items[this.headers[variable]] == "") {
              this.errors.push(tempErr);
            }
          });
        }
      });
      this.$refs.checkErrors.open();
    },
  },
  mounted() {
    this.$store.dispatch("campaigns/add_edit_init");
  },
};
</script>

<style>
</style>